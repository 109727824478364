import React, { useState } from "react";
import usmap from "../../assets/images/usmap.png";
import { Button } from "@material-tailwind/react";
import { openLink } from "../../helpers/utils";
import { useStep } from "../../hooks/hooks";
import { Success, EligibilityTestSuccess } from "../helpers/success/success";
import { Titlesubtitle } from "../helpers/titlesubtitle/titlesubtitle";
import Inputfloat from "../inputs/inputfloat";
import { Phonenumber } from "../inputs/phoneinput";
import { Singleselect } from "../inputs/singleselect";
import { Platformbutton } from "../helpers/button/button";
import { RadioOption, NewVerySmallRadioOption } from "../inputs/radiobutton";
import { Backcontinue } from "../helpers/backcontinue/backcontinue";
import errorsvg from "../../assets/logo-icons/oops.svg"
import "../../components/oeligibility/Oeligibility.scss"
import axios from "axios";
import { getPhoneCode, getCurrencyCode, getCurrencyImg } from "../../helpers/utils";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";

const config = {
  cors: "https://cors-anywhere.herokuapp.com/",
  formUrl:
    "https://docs.google.com/forms/d/1wAjKgN-gPU3T9LK0JVNZc5k4-u6QRtK3t4PuOsBqaBM/formResponse",
};

export const UKTechNatEligibilityTest = (props) => {
  const [state, setState] = useState({
    // fullName: "",
    firstName: "",
    lastName: "",
    gender: "",
    phoneNumber: "",
    email: "",
    country: "",
    expertise: "",
    skilledprofessional : "",
    receivedawards: "",
    ledTeam: "",
    participatedInPrograms: "",
    evidenceOfMentoring: "",
  });
  var ids = [
    "275674196",
    "500981336",
    "364319452",
    "850618021",
    "491281489",
    "1376928382",
    "2110116561",
    "543109535",
    "713045706",
    "540324483",
    "1139714467",
    "39273814",
    "574620756",
  ];
  const { step, setStep, nextStep, previousStep } = useStep(0);
  const [score, setScore] = useState(5);
  var data = [
    {
      name: "skilledprofessional",
      title:
        "Are you a founder, co-founder, tech expert, or a skilled professional with measurable success in your field?",
      value: "yes",
    },
    {
      name: "receivedawards",
      title: "Have you received any major awards, patents, or prizes recognizing your contributions?",
      value: "yes",
    },
    {
      name: "ledTeam",
      title:
        "Have you led cross-functional teams to deliver groundbreaking projects or products?",
      value: "yes",
    },
    {
      name: "participatedInPrograms",
      title:
        "Have you participated in accelerator or incubator programs (e.g., Y Combinator, Techstars)?",
      value: "yes",
    },
    {
      name: "evidenceOfMentoring",
      title: "Do you have evidence of mentoring or coaching others in tech communities?",
      value: "yes",
    },
  ];
  var setInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setState({ ...state, [name]: value });
  };
  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };
  
  var handleOption = (name, value) => {
    // console.log(value);

    // if (name === "criminalRecord") {
    //   // Special case for the criminal record question
    //   if (state[name] === "Yes") {
    //     value === "No" && setScore(score + 1);
    //   } else if (state[name] === "No" || !state[name]) {
    //     value === "Yes" && setScore(score - 1);
    //   }
    // } else {
      // General case for other questionss
      if (state[name] === "Yes" || !state[name]) {
        value === "No" && setScore(score - 1);
      } else if (state[name] === "No") {
        value === "Yes" && setScore(score + 1);
      }
    // }
    setState({ ...state, [name]: value });
  };

  var __renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Stepone
            data={state}
            setInput={setInput}
            handleSelect={handleSelect}
            countries={props.countries}
            click={nextStep}
            setState={setState}
            state={state}
          />
        );
      case 1:
        return (
          <Steptwo
            disabled={
              Object.entries(state)
                .slice(3, 10)
                .every((value) => value[1] !== "")
                ? false
                : true
            }
            score={score}
            data={data.slice(0, 3)}
            name="Continue"
            click={nextStep}
            back={previousStep}
            handleOption={handleOption}
            setState={setState}
            state={state}
          />
        );
      case 2:
        return (
          <Stepthree
            disabled={
              Object.entries(state)
                .slice(9)
                .every((value) => value[1] !== "")
                ? false
                : true
            }
            score={score}
            data={data.slice(3)}
            name="Submit"
            click={() => submit()}
            back={previousStep}
            handleOption={handleOption}
            setState={setState}
            state={state}
          />
        );
      case 3:
        return (
          <EligibilityTestSuccess
            title="Congratulations, You are a Fit for the UK Tech Nation Visa"
            subtitle="Congratulations, you are fit for the UK Tech Nation Visa."
            button="Start Process"
            score={score}
            // onClick={() => {
            //   window.open("https://paystack.com/buy/canadacare");
            //   props.close();
            // }}
            // secondBtntext="Share on Social Media"
            onClick={() => {
              window.open("https://wevesti.com/clarity-session/Tech%20Nation");
            }}
            // secondBtn={() => {
            //   props.close();
            // }}
          />
        );
      case 4:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="We can help you get ready for the UK Tech Nation Visa"
            subtitle="Unfortunately you are not a Fit for the UK Tech Nation Visa at the moment. However, we believe we can help you to decide which other Visa is better, also we can help prepare you for the Canadian Care Giver Visa in 10-20weeks. A member of our team will reach out in 24-48 hours with additional information."
            button="Close"
            onClick={() => {
              props.close();
            }}
          />
        );
      case 5:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Error"
            subtitle="An Error Occurred"
            button="Try Again"
            onClick={() => {
              setStep(1);
            }}
          />
        );
      default:
        return <></>;
    }
  };

  var submit = async () => {
    // console.log(state.country.value);
    // console.log(state.country.label);

    const formData = new FormData();
    var list = Object.keys(state);
    for (var key in state) {
      const value = Array.isArray(state[key])
        ? JSON.stringify(state[key])
        : state[key];
      // eslint-disable-next-line
      value.label
        ? formData.append(
            // eslint-disable-next-line
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function (item) {
                  return item === key;
                })
              ]
            }`,
            value.label
          )
        : formData.append(
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function (item) {
                  return item === key;
                })
              ]
            }`,
            value
          );
    }

    try {
      const res = await axios.post(
        "https://api.wevesti.com/api/v1/general-services/actionable-signup",
        {
          email: state.email,
          score: score,
          type: "uk-tech-nation",
          country: state.country.label,
          fullName: `${state.firstName} ${state.lastName}`,
          firstName: state.firstName,
          lastName: state.lastName,
          // phoneNumber: state.phoneNumber,
          currency: getCurrencyCode(state.country.value)
            ? getCurrencyCode(state.country.value)
            : "NGN",
          currencyFlag: getCurrencyCode(state.country.value)
            ? getCurrencyCode(state.country.value)
            : "https://res.cloudinary.com/wevesti/image/upload/v1698480453/stgcyigpbiadb2g6ipjj.jpg",
          service: "uk-tech-nation",
          phoneNumber: {
            code: parsePhoneNumberFromString(state.phoneNumber)
              .countryCallingCode,
            number: state.phoneNumber.slice(
              getPhoneCode(state.country.label).length
            ),
          },

          skilledprofessional : state.skilledprofessional,
          receivedawards : state.receivedawards,
          ledTeam : state.ledTeam,
          participatedInPrograms: state.participatedInPrograms,
          evidenceOfMentoring: state.evidenceOfMentoring,
        }
      );

      score >= 3 ? setStep(3) : setStep(4);
      //   const { data } = res;
    } catch (error) {
      setStep(5);
    }
  };

  return (
    <div className="w-full">
      {step < 3 && (
        <>
          <p className="text-vestigreen text-[14px] font-bold">
            {step + 1} of 3
          </p>

          <p className="mt-3 mb-1 text-[#14290A] text-[18px] font-bold">
            Check Your Eligibility for a UK Tech Nation Visa
          </p>
          <p className="text-[14px] text-[#2B5219]">
            Take this quiz to see if the UK Tech Nation Visa is a fit for
            you.
          </p>
          {/* <Titlesubtitle
            steps={` ${step + 1} of 3 `}
            title="Check Your Eligibility for a Canadian Caregiver Visa"
            subtitle="Take this quiz to see if the Canadian Caregiver Visa is a fit for you."
          /> */}
        </>
      )}
      <div className="mb-8"></div>
      {__renderStep()}
    </div>
  );
};

const Stepone = (props) => {
  return (
    <div className="ostepone w-full">
      <form className="ostepone__col">
        <div className="ostepone__row">
          {/* <Inputfloat
            type="text"
            label="Full Name"
            name="fullName"
            placeholder="John Doe"
            value={props.data.fullname}
            disabled={false}
            onChange={props.setInput}
          /> */}

          <Inputfloat
            type="text"
            label="First Name"
            name="firstName"
            placeholder="Doe"
            value={props.data.firstName}
            disabled={false}
            onChange={props.setInput}
          />

          <Inputfloat
            type="text"
            label="Last Name"
            name="lastName"
            placeholder="John "
            value={props.data.lastName}
            disabled={false}
            onChange={props.setInput}
          />
        </div>

        <div className="ostepone__row">
          <Singleselect
            label="Gender"
            value={props.data.gender}
            options={[
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
              { label: "Prefer not to say", value: "Prefer not to say" },
            ]}
            onChange={(value) => {
              //console.log(value.value);
              props.handleSelect("gender", value);
            }}
            placeholder="Select your gender "
          />

          <Inputfloat
            type="email"
            label="Email Address"
            name="email"
            placeholder="Johndoe@gmail.com"
            value={props.data.email}
            disabled={false}
            onChange={props.setInput}
          />
        </div>

        <div className="w-full mt-2">
          <Singleselect
            label="Country of residence"
            value={props.data.country}
            options={props.countries}
            onChange={(value) => {
              props.handleSelect("country", value);
            }}
            placeholder="Select your country"
            // components={{ SingleValue: IconSingleValue, Option: IconOption }}
          />
        </div>
        <div className="w-full mt-2">
          <Phonenumber
            value={props.data.phoneNumber}
            country={props.data.country?.vlaue}
            setValue={(value) =>
              props.setState({ ...props.state, phoneNumber: value })
            }
          />
        </div>

        {/* <Singleselect
          label="expertise"
          value={props.data.expertise}
          options={[
            { label: "Arts", value: "Arts" },
            {
              label: "Sciences, Education, Business or Athletics",
              value: "Sciences, Education, Business or Athletics",
            },
            {
              label: "Motion pictures or Television",
              value: "Motion pictures or Television",
            },
          ]}
          onChange={(value) => {
            props.handleSelect("expertise", value);
          }}
          placeholder="Select your expertise"
        /> */}
        {/* <div className='ostepone__row'>
          
          
        </div> */}

        <div className="ostepone__btns">
          <Platformbutton
            name="Continue"
            type="normal"
            disabled={
              Object.entries(props.data)
                .slice(0, 6)
                .every((value) => value[1] !== "")
                ? false
                : true
            }
            click={() => props.click()}
          />
        </div>
      </form>
    </div>
  );
};

const Steptwo = (props) => {
  return (
    <div className=" w-full">
      {props.data.map((item, index) => (
        <div className="ostepone__col w-full">
          <p>{item.title}</p>

          <div className="ostepone__row w-[100%] sm:w-[400px] md:w-[450px] mb-4">
            <div className="w-full">
              <NewVerySmallRadioOption
                changed={(value) => props.handleOption(item.name, value)}
                id={`0 + ${item.name}`}
                isSelected={props.state[item.name] === "Yes"}
                label="Yes"
                value={"Yes"}
              />
            </div>
            <div className="w-full">
              <NewVerySmallRadioOption
                changed={(value) => props.handleOption(item.name, value)}
                id={`1 + ${item.name}`}
                isSelected={props.state[item.name] === "No"}
                label="No"
                value={"No"}
              />
            </div>
          </div>
        </div>
      ))}

      <div className="mt-[-30px]">
        <Backcontinue goBack={() => props.back()}>
          <Platformbutton
            name={props.name}
            type="normal"
            disabled={!props.data.every((item) => props.state[item.name])}
            click={() => props.click()}
          />
        </Backcontinue>
      </div>
    </div>
  );
};

const Stepthree = (props) => {
  return (
    <div>
      <div className=" w-full">
        {props.data.map((item, index) => (
          <div className="ostepone__col w-full">
            <p>{item.title}</p>

            <div className="ostepone__row w-[100%] sm:w-[400px] md:w-[450px] mb-4">
              <div className="w-full">
                <NewVerySmallRadioOption
                  changed={(value) => props.handleOption(item.name, value)}
                  id={`0 + ${item.name}`}
                  isSelected={props.state[item.name] === "Yes"}
                  label="Yes"
                  value={"Yes"}
                />
              </div>
              <div className="w-full">
                <NewVerySmallRadioOption
                  changed={(value) => props.handleOption(item.name, value)}
                  id={`1 + ${item.name}`}
                  isSelected={props.state[item.name] === "No"}
                  label="No"
                  value={"No"}
                />
              </div>
            </div>
          </div>
        ))}

        <div className="mt-[-30px]">
          <Backcontinue goBack={() => props.back()}>
            <Platformbutton
              name={props.name}
              type="normal"
              disabled={!props.data.every((item) => props.state[item.name])}
              click={() => props.click()}
            />
          </Backcontinue>
        </div>
      </div>
    </div>
  );
};
