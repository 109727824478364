import React from 'react'
import ukPotPic from '../../assets/UKTechNatVisa/techPot.svg'
import { Link as AnchorLink } from "react-scroll";

const UkPotential = (props) => {
  return (
    <div className="xl:px-[6%] px-[4%] mb-[6%] md:mt-0 mt-10 md:my-[7%] md:flex md:items-center lg:gap-[10%] gap-[6%]">
      <img
        className="vlg:w-[80rem] xlg:w-[40rem] lg:w-[30rem] md:w-[22rem] w-[100%] rounded-md"
        src={ukPotPic}
        alt=""
      />
      <div>
        <h1 className="text-[27px] sm:text-[28px] md:text-[27px] xl:text-[30px] md:mt-0 mt-2 font-bold">
        Unlock your Tech Potential in the UK
        </h1>
        <p className="text-[#667085] xxl:text-[20px] sm:text-[18px] text-[14px]">
        The UK Tech Nation Visa offers a unique opportunity to help you achieve your career goals and make an impact 
        in the UK’s dynamic tech ecosystem. Get the opportunity to work, live and thrive in the UK’s vibrant digital 
        economy, where talent and innovation meets opportunity.
        </p>
        <div className='mt-8'></div>
        <AnchorLink
          spy={true}
          smooth={true}
          to="ukHeroSection"
          className='border border-[#67A948] bg-[#67A948] rounded-md text-[#ffffff] lg:py-[20px] py-[12px] lg:px-[78px] px-[46px] lg:mt-10 mt-4 xxl:text-[20px] sm:text-[18px] text-[14px] cursor-pointer'>
          Check Eligibility
          </AnchorLink>
        {/* <button
          className="border border-[#67A948] bg-[#67A948] rounded-md text-[#ffffff] lg:py-[20px] py-[12px] lg:px-[78px] px-[46px] lg:mt-10 mt-4 xxl:text-[20px] sm:text-[18px] text-[14px]"
        >
          Check Eligibility
        </button> */}
      </div>
    </div>
  )
}

export default UkPotential
