import React, { useState, useEffect } from "react";

const Typewriter = ({ text, speed = 5, setTypewriterMsg }) => {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText((prev) => prev + text[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      }, speed);
      return () => clearTimeout(timeout);
    } else {
      setTypewriterMsg("done");
    }
  }, [currentIndex, text, speed]);

  return (
    <div
      className="Typewriter FormattedTextComponent bg-[#fff] px-2 pt-2 text-[14px] rounded-b-[8px] rounded-tr-[8px]"
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
};

export default Typewriter;
