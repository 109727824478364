import React, { useEffect, useState } from "react";
import Miainav from "./miainav";
import PostQuestion from "./postQuestion";
import ChatHistory from "./chatHistory";
import Typewriter from "typewriter-effect";
import Typed from "react-typed";

const Miailanding = ({
  height,
  setHeight,
  smallheight,
  setSmallHeight,
  inputText,
  setInputText,
  messages,
  setMessages,
  answer,
  setAnswer,
  typewritermsg,
  setTypewriterMsg,
  loading,
  setLoading,
  addMessage,
}) => {
  // const [inputText, setInputText] = useState("");
  // const [messages, setMessages] = useState([]);
  // const [answer, setAnswer] = useState("");
  // const [minimize, setMinimize] = useState(false);
  const [chatId, setChatId] = useState("");
  // const [typewritermsg, setTypewriterMsg] = useState("");
  // const [loading, setLoading] = useState(false);

  const handleTypingDone = () => {
    setTypewriterMsg("Typing done");
  };

  const generateChatbotResponse = (userInput) => {
    return (
      <Typed
        strings={[answer]}
        typeSpeed={0.001}
        backSpeed={null}
        loop={false}
        // onStart={}
        onComplete={() => {
          // console.log("done");
          setTypewriterMsg("Typing done");
        }}
      />
    );
  };

  return (
    <div
      className={`fixed z-[10000]  bg-[#050E42] w-[19rem] lg:w-[24rem] bottom-0 right-0 h-[${smallheight}] lg:h-[${height}]  flex flex-col justify-between transition-height duration-500 ease-in-out overflow-hidden`}
    >
      <div>
        <Miainav
          setHeight={setHeight}
          height={height}
          setSmallHeight={setSmallHeight}
          smallheight={smallheight}
        />

        {height !== "100vh" ? (
          <div className="h-[20px] hidden  ">
            <ChatHistory
              inputText={inputText}
              addMessage={addMessage}
              generateChatbotResponse={generateChatbotResponse}
              messages={messages}
              setMessages={setMessages}
              answer={answer}
              height={height}
              smallheight={smallheight}
              setInput={(e) => setInputText(e.target.value)}
              setInputText={setInputText}
              loading={loading}
              setLoading={setLoading}
              typewritermsg={typewritermsg}
              setTypewriterMsg={setTypewriterMsg}
            />
          </div>
        ) : (
          <div className="h-[100%]">
            <ChatHistory
              inputText={inputText}
              addMessage={addMessage}
              generateChatbotResponse={generateChatbotResponse}
              messages={messages}
              setMessages={setMessages}
              answer={answer}
              height={height}
              smallheight={smallheight}
              setInput={(e) => setInputText(e.target.value)}
              setInputText={setInputText}
              loading={loading}
              setLoading={setLoading}
              typewritermsg={typewritermsg}
              setTypewriterMsg={setTypewriterMsg}
            />
          </div>
        )}
      </div>

      {height !== "100vh" ? (
        <div className="py-[10px] px-[20px] h-10">
          <PostQuestion
            inputText={inputText}
            setInput={(e) => setInputText(e.target.value)}
            addMessage={addMessage}
            generateChatbotResponse={generateChatbotResponse}
            messages={messages}
            setMessages={setMessages}
            setInputText={setInputText}
            answer={answer}
            setAnswer={setAnswer}
            chatId={chatId}
            setChatId={setChatId}
            smallheight={smallheight}
            height={height}
            typewritermsg={typewritermsg}
            setTypewriterMsg={setTypewriterMsg}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      ) : (
        <div className=" p-[20px] ">
          {" "}
          <PostQuestion
            inputText={inputText}
            setInput={(e) => setInputText(e.target.value)}
            addMessage={addMessage}
            generateChatbotResponse={generateChatbotResponse}
            messages={messages}
            setMessages={setMessages}
            setInputText={setInputText}
            answer={answer}
            setAnswer={setAnswer}
            chatId={chatId}
            setChatId={setChatId}
            smallheight={smallheight}
            height={height}
            typewritermsg={typewritermsg}
            setTypewriterMsg={setTypewriterMsg}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      )}
    </div>
  );
};
export default Miailanding;
