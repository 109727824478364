import img1 from "../../assets/flight/London.jpg";
import img2 from "../../assets/flight/Gatwick.jpg";
import img3 from "../../assets/flight/toronto.jpg";
import img4 from "../../assets/flight/Capetown.jpg";
import img5 from "../../assets/flight/chicago.jpg";
import img7 from "../../assets/flight/newNairaobi.jpg";
import img6 from "../../assets/flight/Johannesburg.jpg";
import img8 from "../../assets/flight/guangzhou.jpg";

export const MyflightOffer = [
  {
    id: 7,
    img: img1,

    title: "Abuja to London",
    text: "Economy, One way Commissionable tickets are available through Royal Air Maroc for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "4th - 20th February 2025",
    price: "₦1,242,398",
  },
  {
    id: 4,
    img: img8,

    title: "Lagos to Guangzhou",
    text: (
      <>
        Economy, One way Commissionable tickets are available through Royal Air
        Maroc for this upcoming flight.
      </>
    ),
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "6th - 20th February 2025",
    price: "₦1,420,337",
  },

  {
    id: 3,
    img: img2,
    title: "Lagos to London (Gatwick)",
    text: "Economy, One way Commissionable tickets are available through Royal Air Maroc for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "3rd - 13th February 2025",
    price: "₦1,450,199",
  },

  {
    id: 2,
    img: img1,
    title: "Lagos to London",
    text: "Economy, One way Commissionable tickets are available through Royal Air Maroc for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "4th - 19th February 2025",
    price: "₦1,726,953",
  },

  {
    id: 7,
    img: img3,

    title: "Lagos to Toronto",
    text: "Economy, One way Commissionable tickets are available through Royal Air Maroc for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "4th - 20th February 2025",
    price: "₦1,910,041",
  },

  // {
  //   id: 8,
  //   img: img7,

  //   title: "Lagos to Nairobi",
  //   text: "Economy, One way Commissionable tickets are available through Royal Air Maroc for this upcoming flight.",
  //   buttonText: "Book your flight now",
  //   offer: "Mid-Week Deal",
  //   date: "23 September - 10 October 2024",
  //   price: "₦1,345,578.00",
  // },

  // {
  //   id: 7,
  //   img: img5,

  //   title: "Lagos to Chicago",
  //   text: "Economy, One way Commissionable tickets are available through Royal Air Maroc for this upcoming flight.",
  //   buttonText: "Book your flight now",
  //   offer: "Mid-Week Deal",
  //   date: "25 September - 14 October 2024",
  //   price: "₦1,957,133.00",
  // },
];
