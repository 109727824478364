import React from 'react'
import { BenefitDataF, BenefitDataS } from "./UkTechNatData";

const UkBenefits = () => {
  return (
    <div className="xl:px-[6%] px-[4%] md:mt-0 mt-8 ">
      <h1 className="text-[27px] sm:text-[28px] md:text-[27px] xl:text-[30px]  font-bold sm:text-center">
      Benefits of the UK Tech Nation Visa
      </h1>
      <div className="sm:flex sm:items-center sm:justify-center vlg:gap-[6%] xlg:gap-[18px] gap-[14px] md:mt-8 mt-4">
        <div className="md:pl-[6%] sm:pl-6">
          {BenefitDataF.map(({ img, title, details }, index) => {
            return (
              <div key={index} className="flex items-center xxl:gap-4 gap-2">
                <img className="xlg:w-[40px] w-[20px]" src={img} alt="" />
                <div className="block pb-4">
                  <p className="font-bold lg:text-[18px] text-[16px]">
                    {title}
                  </p>
                  <p className="text-[#7D7D7D]  xxl:text-[16px]  text-[14px] sm:w-[85%]">
                    {details}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="sm:pl-6">
          {BenefitDataS.map(({ img, title, details }, index) => {
            return (
              <div key={index} className="flex items-center xxl:gap-4 gap-2">
                <img className="xlg:w-[40px] w-[20px]" src={img} alt="" />
                <div className="block pb-4">
                  <p className="font-bold  lg:text-[18px] text-[16px]">
                    {title}
                  </p>
                  <p className="text-[#7D7D7D] xxl:text-[16px]  text-[14px] sm:w-[85%]">
                    {details}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
}

export default UkBenefits
