import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "../../containers/HousingNavBar.jsx";
import Footer from "../../containers/Footer";
import MortgageHousingHero from "./MortgageHousingHero";
import { VestiQues } from "../Partnership/VestiQues";
import { PartNum } from "../Partnership/PartNum";
import MortgageRentSec from "./MortgageRentSec";
import HousingBenefits from "./HousingBenefits";
import {
  Simplemodal,
  Notificationmodal,
} from "../helpers/modal/simplifiedmodal";
import { EB2eligibility } from "../ebnw/EB2Banner";
import { CareGiverEligibilityTest } from "../CareGiverEligtest";
import { LiaTimesSolid } from "react-icons/lia";
import Users from "./Users";
import { Reviews } from "../Partnership/Reviews";
import SchoolsTestimony from "../ebnw/SchoolsTestimony.js";
import { MortgagesForm } from "../Mortgagesform.js";
import { Flightvirtualcardadvert } from "../FlightLandingPage/flightvirtualcardadvert.js";
import { Advert } from "../FlightLandingPage/advert.js";
// Flightvirtualcardadvert;

const MortgageAndRent = () => {
  const [open, setOpen] = useState(false);
  const [appointmentModal, setAppointmentModal] = useState(false);
  const [countries, setCountries] = useState(false);

  useEffect(() => fetchAllCountries(), []);

  const fetchAllCountries = () => {
    const url = "https://restcountries.com/v3.1/all";
    axios
      .get(url)
      .then((res) => {
        var options = (res.data ?? []).map((item) => ({
          image: item.flags.svg,
          label: item.name.common,
          value: item.cca3,
          currency: item.currencies,
        }));
        setCountries(options);
      })
      .catch((error) => error);
  };

  console.log(countries);
  return (
    <div>
      <Notificationmodal open={open} onClick={() => setOpen(false)}>
        <LiaTimesSolid
          onClick={() => setOpen(false)}
          className="text-[#000] absolute top-4 right-8 text-[24px] cursor-pointer"
        />
        <div className="w-[100%] sm:w-[400px] md:w-[450px] flex flex-col items-center">
          <MortgagesForm countries={countries} close={() => setOpen(false)} />
        </div>
      </Notificationmodal>

      <NavBar />
      <MortgageHousingHero
        open={open}
        setOpen={setOpen}
        appointmentModal={appointmentModal}
        setAppointmentModal={setAppointmentModal}
        countries={countries}
        setCountries={setCountries}
      />
      <MortgageRentSec open={open} setOpen={setOpen} />
      {/* <PartNum /> */}
      <HousingBenefits />
      <Advert />
      {/* <Users /> */}
      <SchoolsTestimony
        title={
          <>
            <h1 className="text-3xl text-white font-fontSemi text-center">
              What our users are <span className="text-vestigreen">saying</span>
            </h1>
            <p className="text-center text-white text-lg font-fontReg">
              Don't take our words for it, see what our users are saying.
            </p>
          </>
        }
      />
      <VestiQues />
      <Footer />
    </div>
  );
};

export default MortgageAndRent;
