import Techstars1 from "../assets/logo-icons/techstars1.svg";
import Techcity1 from "../assets/logo-icons/techcity1.svg";
import Bloomberg1 from "../assets/logo-icons/bloomberg1.svg";
import CareerImage from "../assets/career/careerpage.svg";
// import ButtonTab from "../components/Button";
import { openLink } from "../helpers/helpers";

const CareersBanner = () => {
  return (
    <div className="w-full bg-secondary">
      <div className="py-32 container mx-auto flex flex-col lg:flex-row lg:justify-between lg:items-center">
        <div className="lg:w-[45%] mb-6 lg:mb-0">
          <img src={CareerImage} alt="career-pics" />
        </div>
        <div className="lg:w-[45%]">
          <h1 className="text-[#ffffff] font-semibold text-5xl pb-4 font-fontSemi">
            Careers at Vesti
          </h1>
          <p className="text-[#fff] text-xl pb-12 font-fontReg">
            Work in a place you love solving problems you are passionate about.
          </p>

          {/* <ButtonTab
            link="https://blog1.wevesti.com/opportunities/"
            CTA="See Open Roles"
            styles="p-4"
            width="50%"
          /> */}

          <div
            // onClick={() => openLink("https://wevesti.com/maintenance")}
            onClick={() => openLink("https://blog.wevesti.com/careers/")}
            className={` p-4 lg:w-[50%] sm:w-[50%] font-fontReg text-[#fff] text-center bg-[#67A948] cursor-pointer transition duration-700 ease-in-out hover:bg-[#3E6F26]  rounded-xl`}
          >
            See Open Roles
            {/* <Link onClick={() => window.open(link, "_blank")}>{CTA}</Link> */}
          </div>

          {/* // <button className="bg-[#67A948] p-4 rounded-xl w-72 text-[#ffffff]">
          //   See Open Roles
          // </button> */}

          <div className=" flex flex-wrap pt-16  lg:mt-0 lg:mb-10  items-center opacity-80 gap-12 xl:gap-10">
            <img
              className="cursor-pointer"
              src={Techstars1}
              alt="techstars"
              onClick={() =>
                openLink(
                  "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022"
                )
              }
            />
            <img
              className="cursor-pointer"
              src={Techcity1}
              alt="techcity"
              onClick={() =>
                openLink(
                  "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/"
                )
              }
            />
            <img
              className="xxl:flex justify-center"
              src={Bloomberg1}
              alt="bloomberg"
              onClick={() =>
                openLink(
                  "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians"
                )
              }
            />
          </div>

          {/* <div className="pt-16 flex flex-wrap items-center opacity-50">
            <img
              className=""
              src={Techstars1}
              alt="techstars"
              onClick={() =>
                openLink(
                  "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022"
                )
              }
            />
            <img
              className="px-8"
              src={Techcity1}
              alt="techcity"
              onClick={() =>
                openLink(
                  "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/"
                )
              }
            />
            <img
              className=""
              src={Bloomberg1}
              alt="bloomberg"
              onClick={() =>
                openLink(
                  "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians"
                )
              }
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CareersBanner;
