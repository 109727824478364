import React, { useState, useEffect } from "react";
import { SelectCurrencyThree } from "../inputs/selectCurrency";
import { Platformbutton } from "../helpers/button/button";
import axios from "../api/axios";
import { Notificationmodal } from "../helpers/modal/simplifiedmodal";
import { FaTimes } from "react-icons/fa";
import { LiaTimesSolid } from "react-icons/lia";
import { GoArrowRight } from "react-icons/go";
import { useParams, useLocation } from "react-router-dom";
import { openLink } from "../../helpers/helpers";
import errorsvg from "../../assets/logo-icons/oops.svg";
import { Success } from "../helpers/success/success";

export const PaymentSection = (props) => {
  const [rate, setRate] = useState(null);
  let { referrer } = useParams();
  const location = useLocation();

  const [errorModal, setErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const queryParams = new URLSearchParams(location.search);
  const flagforReferrer = queryParams.get("changeheretostage1");

  const [selected, setSelected] = useState({
    currencyFlag:
      "https://cdn.pixabay.com/photo/2012/04/10/16/22/united-26177__480.png",
    currency: "USD",
  });

  const LotteryRegistration = props.h1bFee?.fee[1].amount / 100;
  const LotteryRegistrationRef = props.h1bFee?.fee[2].amount / 100;

  const RecruiterFee = props.isChecked ? 0 : props.h1bFee?.fee[3].amount / 100;

  const totalAmount = LotteryRegistration + RecruiterFee;

  const refTotalAmount = LotteryRegistrationRef + RecruiterFee;

  // with referrers
  const refNGNPricetotal = props.isChecked ? 664000 : 1186900;
  const refNGNPriceLottery = 664000;
  const refNGNPriceRecruiter = refNGNPricetotal - refNGNPriceLottery;

  // without referrer
  const NGNPricetotal = props.isChecked ? 1120000 : 1600000;
  const NGNPriceLottery = 1120000;
  const NGNPriceRecruiter = NGNPricetotal - NGNPriceLottery;

  const currencies = [
    {
      currencyFlag:
        "https://cdn.pixabay.com/photo/2012/04/10/16/22/united-26177__480.png",
      currency: "USD",
    },
    {
      currencyFlag: "https://flagcdn.com/ng.svg",
      currency: "NGN",
    },
    // {
    //   currencyFlag: "",
    //   currency: "Others",
    // },
  ];

  const handlePayment = async (formId) => {
    const nameParts = props.state.fullName.split(" ");

    props.setIsEmptyFieldErrCheck(true);
    // setOpenIframeFull(true);

    const url = `https://pathwayapi.wevesti.com/api/v1/pathway/pay-for-visa-document-no-auth`;

    const payload = {
      id: formId,
      email: props.state.email,
      fullname: props.state.fullName,
      firstName: nameParts[0],
      lastName: nameParts[1],
      currency: selected.currency,
      amount:
        selected.currency == "NGN"
          ? NGNPricetotal * 100
          : totalAmount * rate * 100,
      visaType: "H1B",
      paymentPercent: 100,
      amountPaid:
        selected.currency == "NGN"
          ? NGNPricetotal * 100
          : totalAmount * rate * 100,
      // id: props.formId,
      paymentOption: "card",
      totalCost:
        selected.currency == "NGN"
          ? NGNPricetotal * 100
          : totalAmount * rate * 100,
    };

    const referrerPayload = {
      email: props.state.email,
      fullname: props.state.fullName,
      currency: selected.currency,
      amount:
        selected.currency == "NGN"
          ? refNGNPricetotal * 100
          : refTotalAmount * rate * 100,
      visaType: "H1B",
      paymentPercent: 100,
      amountPaid:
        selected.currency == "NGN"
          ? refNGNPricetotal * 100
          : refTotalAmount * rate * 100,
      paymentOption: "card",
      totalCost:
        selected.currency == "NGN"
          ? refNGNPricetotal * 100
          : refTotalAmount * rate * 100,
    };

    if (
      props.selectedFiles[0]?.size &&
      props.state.fullName &&
      props.state.email &&
      props.state.dob &&
      props.state.degree !== "No" &&
      (props.isChecked ? props.state.EmployerAddress : true) &&
      (props.isChecked ? props.state.companyName : true) &&
      (props.isChecked ? props.state.Ein : true) &&
      (props.isChecked ? props.state.employerEmail : true) &&
      (props.isChecked ? props.state.employerContact : true)
    ) {
      // setOpenIframeFull(true);

      try {
        const res = await axios.post(url, referrer ? referrerPayload : payload);
        const { data } = res;

        // window.open(data?.data?.url);

        // setTimeout(() => {
        //   openLink(data?.data?.url, "_blank");
        // }, 100);

        setTimeout(() => {
          if (data?.data?.url) {
            window.location.href = data.data.url;
          } else {
            console.error("No URL found in the response.");
          }
        }, 100);
        // submitApplication();
      } catch (error) {
        console.log(error);
        setErrorMsg(error?.response?.data?.message);
        setErrorModal(true);
      }
    }

    // setOpenIframeFull(true);
    // isEmptyFieldErrCheck={props.isEmptyFieldErrCheck}
    //       setIsEmptyFieldErrCheck={props.setIsEmptyFieldErrCheck}
    //     const totalAmount = LotteryRegistration + RecruiterFee;
  };

  const submitApplication = async () => {
    const nameParts = props.state.fullName.split(" ");

    var url =
      "https://pathwayapi.wevesti.com/api/v1/pathway/submit-visa-documents-no-auth";

    const formData = new FormData();
    formData.append("immigrationPassport", props.selectedFiles[0]);
    formData.append("visaType", "H1B");

    // formData.append("email", props.state.email);

    formData.append("submitted", true);
    formData.append("fileUploadPercentage", 14);
    // formData.append("hasWatchedPathwayVideo", true);

    formData.append("hasCompanyFilling", props.isChecked ? true : false);
    formData.append("dateOfBirth", props.state.dob);
    formData.append("fullname", props.state.fullName);
    formData.append("firstName", nameParts[0]);

    formData.append("lastName", nameParts[1]);
    formData.append("email", props.state.email);
    if (referrer) {
      formData.append("referrer", referrer);
    }

    formData.append(
      "hasBachelorsDegree",
      props.state.degree === "Yes" ? true : false
    );
    // formData.append("middleName", props.state.middleName);
    formData.append(
      "companyFilling",
      props.isChecked ? props.state.companyName : "N/A"
    );

    if (props.isChecked) {
      formData.append("companyFillingName", props.state.companyName);
      formData.append("companyFillingEIN", props.state.Ein);
      formData.append("companyFillingEmail", props.state.employerEmail);
      formData.append("companyFillingPhoneNumber", props.state.employerContact);
      formData.append("companyFillingAddress", props.state.EmployerAddress);
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      // console.log(data);
      handlePayment(data?.id);
      // props.setStep(2);
      // localStorage.removeItem("mainStackPayment");
    } catch (error) {
      // console.error("Error uploading file:", error);
      setErrorMsg(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "An error occurred while trying to make payment. Kindly reach out to austin@wevesti.com for technical assistance."
      );

      setErrorModal(true);

      // dispatch(updateUploadedFiles({}));
    }
  };

  useEffect(() => {
    const fetchpriceData = async () => {
      // setIsSuccess(false);
      try {
        const response = await axios.get(
          `v1/vesti-fx/lists?currency=${
            selected.currency == "Others" ? "USD" : selected.currency
          }
           `
        );
        const usdConversionRate = response.data?.usdConversionRate;
        setRate(usdConversionRate);
      } catch (error) {
        console.log(error);
      }
    };

    fetchpriceData();
  }, [selected.currency]);

  // const toWholeCurrency = (num) => num / 100;

  const toWholeCurrency = (num) => {
    // Divide by 100 to get the correct value
    const value = num / 100;

    // Format the number with commas as thousand separators
    const formattedValue = value.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formattedValue;
  };

  const handleCurrencyChange = (selectedCurrency) => {
    // setSelected(selectedCurrency);
  };

  const [openIframeFull, setOpenIframeFull] = useState(false);

  return (
    <div>
      <Notificationmodal
        open={openIframeFull}
        onClick={() => setOpenIframeFull(false)}
      >
        <LiaTimesSolid
          onClick={() => setOpenIframeFull(false)}
          className="text-[#000] absolute top-4 right-8 text-[24px] cursor-pointer"
        />{" "}
        <div className="mainStackpopup" style={{ position: "relative" }}>
          {flagforReferrer || referrer ? (
            <div
              style={{
                position: "absolute",
                width: "100%",
                left: "0px",
                top: "0px",
                right: "0px",
                bottom: "0px",
                height: "100%",
              }}
            >
              {props.isChecked ? (
                <iframe
                  id="mainstack_checkout_iframe"
                  src={`https://checkout.mainstack.me/?productId=lteYcrPsg2-y&type=speed&currency=${
                    selected?.currency == "Others" ? "" : selected?.currency
                  }`}
                  style={{
                    height: "100%",
                    width: "100%",
                    border: "0",
                    borderRadius: "20px",
                    padding: "0",
                  }}
                  allow="payment"
                ></iframe>
              ) : (
                <iframe
                  id="mainstack_checkout_iframe"
                  // src={`https://mainstack.store/wevesti/_ltkW97_AiOl/checkout`}
                  src={`https://checkout.mainstack.me/?productId=_ltkW97_AiOl&type=speed&currency=${
                    selected?.currency == "Others" ? "" : selected?.currency
                  }`}
                  style={{
                    height: "100%",
                    width: "100%",
                    border: "0",
                    borderRadius: "20px",
                    padding: "0",
                  }}
                  allow="payment"
                ></iframe>
              )}
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                width: "100%",
                left: "0px",
                top: "0px",
                right: "0px",
                bottom: "0px",
                height: "100%",
              }}
            >
              {props.isChecked ? (
                <iframe
                  id="mainstack_checkout_iframe"
                  src={`https://checkout.mainstack.me/?productId=UzdwGXfvqjhU&type=speed&currency=${
                    selected?.currency == "Others" ? "" : selected?.currency
                  }`}
                  style={{
                    height: "100%",
                    width: "100%",
                    border: "0",
                    borderRadius: "20px",
                    padding: "0",
                  }}
                  allow="payment"
                ></iframe>
              ) : (
                <iframe
                  id="mainstack_checkout_iframe"
                  src={`https://checkout.mainstack.me/?productId=v7TwEInChz8k&type=speed&currency=${
                    selected?.currency == "Others" ? "" : selected?.currency
                  }`}
                  style={{
                    height: "100%",
                    width: "100%",
                    border: "0",
                    borderRadius: "20px",
                    padding: "0",
                  }}
                  allow="payment"
                ></iframe>
              )}
            </div>
          )}
        </div>
        {/* )} */}
      </Notificationmodal>

      <Notificationmodal open={errorModal} onClick={() => setErrorModal(false)}>
        <LiaTimesSolid
          onClick={() => setErrorModal(false)}
          className="text-[#000] absolute top-4 right-8 text-[24px] cursor-pointer"
        />{" "}
        <Success
          image={errorsvg}
          type="error"
          title="An Error Occurred"
          subtitle={errorMsg}
          button="Close"
          onClick={() => {
            setErrorModal(false);
          }}
        />
      </Notificationmodal>

      <div className="relative w-full h-full">
        <div className="Hb1paymentSection">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#1D2939",
              fontWeight: "600",
              padding: "10px 10px",
            }}
          >
            <p className="text-[14px]">INVOICE</p>
            <p className="text-[14px]">Download</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F9FAFB",
              padding: "10px 10px",
              borderBottom: "1px solid #d0d5dd",
            }}
          >
            <p>Currency</p>

            {/* <p style={{ color: '#1D2939', fontWeight: '600' }}>USD</p> */}

            <div className="">
              <SelectCurrencyThree
                setSelected={setSelected}
                selected={selected}
                options={currencies.map((item) => ({
                  value: item.currency,
                  label: "",
                  // image: __renderWalletSvg(props.selected?.name),
                  image: item.currencyFlag ? item.currencyFlag : "",
                  ...item,
                }))}
                handleCurrencyChange={handleCurrencyChange}
              />
            </div>
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F9FAFB",
              padding: "10px 10px",
              borderBottom: "1px solid #d0d5dd",
            }}
          >
            {" "}
            <p>Lottery Registration & Clarity Fees</p>
            {selected.currency === "NGN" ? (
              <p style={{ color: "#1D2939", fontWeight: "600" }}>
                {selected?.currency === "USD" || selected.currency == "Others"
                  ? "$"
                  : "₦"}
                {flagforReferrer || referrer
                  ? toWholeCurrency(refNGNPriceLottery * 100)
                  : toWholeCurrency(NGNPriceLottery * 100)}
              </p>
            ) : (
              <p style={{ color: "#1D2939", fontWeight: "600" }}>
                {selected?.currency === "USD" || selected.currency == "Others"
                  ? "$"
                  : "₦"}
                {flagforReferrer || referrer
                  ? toWholeCurrency(LotteryRegistrationRef * rate * 100)
                  : toWholeCurrency(LotteryRegistration * rate * 100)}
              </p>
            )}
          </div>{" "}
          <div
            // className="h1bOptioonalFee"
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F9FAFB",
              padding: "10px 10px",
            }}
          >
            {" "}
            <p>Recruiter’s Fee</p>
            {selected.currency === "NGN" ? (
              <p style={{ color: "#1D2939", fontWeight: "600" }}>
                {selected.currency === "USD" || selected.currency == "Others"
                  ? "$"
                  : "₦"}
                {flagforReferrer || referrer
                  ? toWholeCurrency(refNGNPriceRecruiter * 100)
                  : toWholeCurrency(NGNPriceRecruiter * 100)}{" "}
              </p>
            ) : (
              <p style={{ color: "#1D2939", fontWeight: "600" }}>
                {selected.currency === "USD" || selected.currency == "Others"
                  ? "$"
                  : "₦"}
                {toWholeCurrency(RecruiterFee * rate * 100)}{" "}
              </p>
            )}
          </div>
          {selected.currency === "NGN" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#1D2939",
                fontWeight: "600",
                padding: "10px 10px",
              }}
            >
              {" "}
              <p>Total amount</p>
              <p>
                {selected.currency === "USD" || selected.currency == "Others"
                  ? "$"
                  : "₦"}
                {flagforReferrer || referrer
                  ? toWholeCurrency(refNGNPricetotal * 100)
                  : toWholeCurrency(NGNPricetotal * 100)}{" "}
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#1D2939",
                fontWeight: "600",
                padding: "10px 10px",
              }}
            >
              {" "}
              <p>Total amount</p>
              <p>
                {selected.currency === "USD" || selected.currency == "Others"
                  ? "$"
                  : "₦"}

                {flagforReferrer || referrer
                  ? toWholeCurrency(refTotalAmount * rate * 100)
                  : toWholeCurrency(totalAmount * rate * 100)}
              </p>
            </div>
          )}
        </div>

        {selected.currency === "NGN" ? (
          <div
            onClick={() => {
              submitApplication();
            }}
            className="bg-vestigreen text-white p-4 w-full flex items-center justify-center cursor-pointer text-center rounded-md text-[16px] font-[500]"
          >
            <GoArrowRight className="pay-icon mr-8" />
            Pay{" "}
            {selected.currency === "USD" || selected.currency == "Others"
              ? "$"
              : "₦"}
            {flagforReferrer || referrer
              ? toWholeCurrency(refNGNPricetotal * 100)
              : toWholeCurrency(NGNPricetotal * 100)}{" "}
            {/* {selected.currency === "NGN" ?  } */}
            {/* {flagforReferrer
              ? toWholeCurrency(refTotalAmount * rate * 100)
              : toWholeCurrency(totalAmount * rate * 100)} */}
            {/* {toWholeCurrency(totalAmount * rate * 100)} */}
            {!selected.currency === "USD" ||
            !selected.currency == "Others" ||
            props.isChecked ||
            selected.currency === "NGN" ? (
              ""
            ) : (
              <s className="ml-3 mr-2">$1500</s>
            )}{" "}
            and Submit
          </div>
        ) : (
          <div
            onClick={() => {
              submitApplication();
            }}
            className="bg-vestigreen text-white p-4 w-full flex items-center justify-center cursor-pointer text-center rounded-md text-[16px] font-[500]"
          >
            <GoArrowRight className="pay-icon mr-8" />
            Pay{" "}
            {selected.currency === "USD" || selected.currency == "Others"
              ? "$"
              : "₦"}
            {/* {selected.currency === "NGN" ? flagforReferrer ? refNGNPricetotal : NGNPricetotal } */}
            {flagforReferrer || referrer
              ? toWholeCurrency(refTotalAmount * rate * 100)
              : toWholeCurrency(totalAmount * rate * 100)}
            {/* {toWholeCurrency(totalAmount * rate * 100)} */}
            {!selected.currency === "USD" ||
            !selected.currency == "Others" ||
            props.isChecked ||
            selected.currency === "NGN" ? (
              ""
            ) : (
              <s className="ml-3 mr-2">$1500</s>
            )}{" "}
            and Submit
          </div>
        )}

        <p className="mt-2 text-red-500 text-[12px] font-[600]">
          {(!props.state.fullName && props.isEmptyFieldErrCheck) ||
          (!props.state.email && props.isEmptyFieldErrCheck) ||
          (!props.state.dob && props.isEmptyFieldErrCheck)
            ? "* All fields are required. Please ensure no field is left blank."
            : ""}
        </p>
        {/* <Platformbutton
        name={`Pay ${selected.currency === "USD" ? "$" : "₦"}${toWholeCurrency(
          totalAmount * rate * 100
        )}  `}
        type="normal"
        click={() => {
          handlePayment();
        }}
        disabled={!props.state.email || !props.state.fullName ? true : false}
      /> */}
      </div>
    </div>
  );
};
