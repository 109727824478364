import React, { useState } from "react";
import usmap from "../assets/images/usmap.png";
import { Button } from "@material-tailwind/react";
import { openLink } from "../helpers/helpers";
import { useStep } from "../hooks/hooks";
import { Success, EligibilityTestSuccess } from "./helpers/success/success";
import { Titlesubtitle } from "./helpers/titlesubtitle/titlesubtitle";
import Inputfloat from "./inputs/inputfloat";
import { Phonenumber } from "./inputs/phoneinput";
import {
  // IconOption,
  // IconSingleValue,
  Singleselect,
} from "./inputs/singleselect";
import { Platformbutton } from "./helpers/button/button";
import { RadioOption, NewVerySmallRadioOption } from "./inputs/radiobutton";
import { Backcontinue } from "./helpers/backcontinue/backcontinue";
import errorsvg from "../assets/logo-icons/oops.svg";
import "../components/oeligibility/Oeligibility.scss";
import axios from "axios";
import {
  getPhoneCode,
  getCurrencyCode,
  getCurrencyImg,
} from "../helpers/utils";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";
import { allCountries } from "../components/helpers/countryregion";

const config = {
  cors: "https://cors-anywhere.herokuapp.com/",
  formUrl:
    "https://docs.google.com/forms/d/1wAjKgN-gPU3T9LK0JVNZc5k4-u6QRtK3t4PuOsBqaBM/formResponse",
};

//docs.google.com/forms/d/1bfQT7elaQImW0iCIps0b2HAfXkG5BHRAHK_RUf5O-L0/edit?ts=6731e585
export const MortgagesForm = (props) => {
  const [state, setState] = useState({
    // fullName: "",
    firstName: "",
    lastName: "",
    gender: "",
    phoneNumber: "",
    email: "",
    country: "",
    financialOption: "",

    // speaker: "",
  });
  var ids = [
    "275674196",
    "500981336",
    "364319452",
    "850618021",
    "491281489",
    "1376928382",
    "2110116561",
    "543109535",
    "713045706",
    "540324483",
    "1139714467",
    "39273814",
    "574620756",
  ];
  const { step, setStep, nextStep, previousStep } = useStep(0);
  const [score, setScore] = useState(5);

  var setInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setState({ ...state, [name]: value });
  };
  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };
  //   var handleOption = (name, value) => {
  //     if (state[name] === "Yes" || !state[name]) {
  //       value === "No" && setScore(score - 1);
  //     } else if (state[name] === "No") {
  //       value === "Yes" && setScore(score + 1);
  //     }
  //     setState({ ...state, [name]: value });
  //   };

  var __renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Stepone
            data={state}
            setInput={setInput}
            handleSelect={handleSelect}
            countries={props.countries}
            // click={nextStep}
            click={() => submit()}
            setState={setState}
            state={state}
          />
        );

      case 3:
        return (
          <Success
            title="Form submitted successfully"
            subtitle="Thank you for providing your details. You will receive a confirmation email shortly, and a member of our team will get in touch with you soon."
            button="Okay, thank you"
            score={score}
            // onClick={() => {
            //   window.open("https://paystack.com/buy/canadacare");
            //   props.close();
            // }}
            // secondBtntext="Share on Social Media"
            onClick={() => {
              props.close();
            }}
            // secondBtn={() => {
            //   props.close();
            // }}
          />
        );

      case 4:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Error"
            subtitle="An Error Occurred"
            button="Try Again"
            onClick={() => {
              setStep(1);
            }}
          />
        );
      default:
        return <></>;
    }
  };

  var submit = async () => {
    // console.log(state.country.value);
    // console.log(state.country.label);

    const formData = new FormData();
    var list = Object.keys(state);
    for (var key in state) {
      const value = Array.isArray(state[key])
        ? JSON.stringify(state[key])
        : state[key];
      // eslint-disable-next-line
      value.label
        ? formData.append(
            // eslint-disable-next-line
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function (item) {
                  return item === key;
                })
              ]
            }`,
            value.label
          )
        : formData.append(
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function (item) {
                  return item === key;
                })
              ]
            }`,
            value
          );
    }

    try {
      const res = await axios.post(
        "https://api.wevesti.com/api/v1/general-services/actionable-signup",
        {
          email: state.email,
          //   score: score,
          type: "financial-housing",
          service: "financial-housing",
          country: state.country.label,
          fullName: `${state.firstName} ${state.lastName}`,
          firstName: state.firstName,
          lastName: state.lastName,
          financialOption: state.financialOption.value,
          // phoneNumber: state.phoneNumber,
          currency: getCurrencyCode(state.country.value)
            ? getCurrencyCode(state.country.value)
            : "NGN",
          currencyFlag: getCurrencyCode(state.country.value)
            ? getCurrencyCode(state.country.value)
            : "https://res.cloudinary.com/wevesti/image/upload/v1698480453/stgcyigpbiadb2g6ipjj.jpg",

          phoneNumber: {
            code: parsePhoneNumberFromString(state.phoneNumber)
              .countryCallingCode,
            number: state.phoneNumber.slice(
              getPhoneCode(state.country.label).length
            ),
          },

          //   certifications: state.certifications,
          //   medicalProfessional: state.medicalProfessional,
          //   twoYearsPlusExperience: state.twoYearsPlusExperience,
          //   languageProficiency: state.languageProficiency,
          //   criminalRecord: state.criminalRecord,
        }
      );
      setStep(3);
      //   score >= 3 ? setStep(3) : setStep(4);
      //   const { data } = res;
    } catch (error) {
      setStep(4);
    }
  };

  //syca-app-backend.eba-pe3mzmfm.us-east-1.elasticbeanstalk.com/api
  // http:
  return (
    <div className="w-full">
      {step < 3 && (
        <>
          {/* <p className="text-vestigreen text-[14px] font-bold">
            {step + 1} of 1
          </p> */}

          <p className="mt-3 mb-1 text-[#14290A] text-[18px] font-bold">
            Join the Waitlist for Tailored Housing Solutions
          </p>
          <p className="text-[14px] text-[#2B5219]">
            Complete the form to secure your spot and access tailored housing
            options.
          </p>
          {/* <Titlesubtitle
            steps={` ${step + 1} of 3 `}
            title="Check Your Eligibility for a Canadian Caregiver Visa"
            subtitle="Take this quiz to see if the Canadian Caregiver Visa is a fit for you."
          /> */}
        </>
      )}
      <div className="mb-8"></div>
      {__renderStep()}
    </div>
  );
};

const Stepone = (props) => {
  return (
    <div className="ostepone w-full">
      <form className="ostepone__col">
        <div className="ostepone__row">
          {/* <Inputfloat
            type="text"
            label="Full Name"
            name="fullName"
            placeholder="John Doe"
            value={props.data.fullname}
            disabled={false}
            onChange={props.setInput}
          /> */}

          <Inputfloat
            type="text"
            label="First Name"
            name="firstName"
            placeholder="Doe"
            value={props.data.firstName}
            disabled={false}
            onChange={props.setInput}
          />

          <Inputfloat
            type="text"
            label="Last Name"
            name="lastName"
            placeholder="John "
            value={props.data.lastName}
            disabled={false}
            onChange={props.setInput}
          />
        </div>

        <div className="ostepone__row">
          <Singleselect
            label="Gender"
            value={props.data.gender}
            options={[
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
              { label: "Prefer not to say", value: "Prefer not to say" },
            ]}
            onChange={(value) => {
              //console.log(value.value);
              props.handleSelect("gender", value);
            }}
            placeholder="Select your gender "
          />

          <Inputfloat
            type="email"
            label="Email Address"
            name="email"
            placeholder="Johndoe@gmail.com"
            value={props.data.email}
            disabled={false}
            onChange={props.setInput}
          />
        </div>

        <div className="ostepone__row">
          <Singleselect
            label="Country"
            value={props.data.country}
            options={allCountries.map((country) => ({
              value: country.countryShortCode,
              label: country.countryName,
            }))}
            onChange={(value) => {
              props.handleSelect("country", value);
            }}
            placeholder="Select your country"
          />
          {/* <Singleselect
            label="Country of residence"
            value={props.data.country}
            options={props.countries}
            onChange={(value) => {
              props.handleSelect("country", value);
            }}
            placeholder="Select your country"
          /> */}

          <Singleselect
            label="Choose Financing Option"
            value={props.data.financialOption}
            options={[
              { label: "Mortgage", value: "Mortgage" },
              { label: "Rent Payment", value: "Rent Payment" },
              { label: "Need Guidance", value: "Need Guidance" },
            ]}
            onChange={(value) => {
              //console.log(value.value);
              props.handleSelect("financialOption", value);
            }}
            placeholder="Choose Financing Option "
          />
        </div>
        <div className="w-full mt-2">
          <Phonenumber
            value={props.data.phoneNumber}
            country={props.data.country?.vlaue}
            setValue={(value) =>
              props.setState({ ...props.state, phoneNumber: value })
            }
          />
        </div>

        {/* <Singleselect
          label="expertise"
          value={props.data.expertise}
          options={[
            { label: "Arts", value: "Arts" },
            {
              label: "Sciences, Education, Business or Athletics",
              value: "Sciences, Education, Business or Athletics",
            },
            {
              label: "Motion pictures or Television",
              value: "Motion pictures or Television",
            },
          ]}
          onChange={(value) => {
            props.handleSelect("expertise", value);
          }}
          placeholder="Select your expertise"
        /> */}
        {/* <div className='ostepone__row'>
          
          
        </div> */}

        <div className="ostepone__btns">
          <Platformbutton
            name="Proceed"
            type="normal"
            disabled={
              Object.entries(props.data)
                .slice(0, 7)
                .every((value) => value[1] !== "")
                ? false
                : true
            }
            click={() => props.click()}
          />
        </div>
      </form>
    </div>
  );
};
