import TwitterLogo1 from "../assets/logo-icons/twitter-logo1.svg";
import LinkedinLogo1 from "../assets/logo-icons/linkedin-logo1.svg";
import { People } from "../constant/TeamsDetail";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";

const MeetTheTeam = ({ link, count }) => {
  return (
    <div>
      <div className=" mx-auto lg:w-[90%] px-6 text-center">
        <div className="py-4">
          <h1 className="font-bold text-[28px] pb-14 md:pb-14 text-[#14290A]">Leadership Team</h1>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-8 sm:gap-10 pb-14 font-fontReg">
            {People.slice(0, count).map((person) => (
              <div className="flex flex-col items-center">
                <div>
                  <img
                    src={person.headShot}
                    alt="team-member"
                    className="rounded-full sm:h-[10rem] sm:w-[10rem] md:h-[12rem] w-[8rem] h-[8rem] md:w-[12rem] object-cover object-top"
                  />
                </div>
                <div className="pb-3">
                  <h3 className="text-[#14290A] font-fontReg text-l">
                    {person.fullName}
                  </h3>
                  <p className="text-[#14290A] font-fontLight text-sm">
                    {person.designation}
                  </p>
                </div>
                <div className="flex items-center gap-1">
                  <Link to={person.socialLink1}>
                    <img
                      className="w-[18px]"
                      src={LinkedinLogo1}
                      alt="linkedin-logo"
                    />
                  </Link>
                  <Link to={person.socialLink2}>
                  <FaXTwitter
                  className="w-[22px] text-[#000000]"
                  />
                    {/* <img  
                    className="w-[22px]" 
                    src={<FaXTwitter/>} 
                    alt="twitter-logo" /> */}
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div>
            <Link
              to="/team"
              className="text-[#67A948] text-xl cursor-pointer font-fontReg hover:text-headcol"
            >
              {link}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetTheTeam;
