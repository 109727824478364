import React from 'react'
import numplus from '../../assets/UKTechNatVisa/numplus.png'
import tick from '../../assets/UKTechNatVisa/tick.png'
import { Link as AnchorLink } from "react-scroll";

const UkNum = () => {
  return (
    <div className=" md:flex md:flex-col md:items-center mb-[5%] px-4">
    <div className=" md:flex md:items-center rounded-md bg-[#060E42] md:w-[96%] xl:w-[91%] xlg:w-[90%] p-8">
      <div className="relative mb-4 sm:flex sm:items-center sm:gap-10 md:flex md:items-center md:gap-10 xl:flex xl:items-center xl:gap-10 xlg:flex xlg:items-center xlg:gap-10 w-[100%] md:w-[90%] ">
        <div className='relative'>
        <img
          src={numplus}
          alt=""
          className=" w-[20%] sm:w-[100%]"
        />
        <img src={tick} alt="" className='absolute right-[80%] sm:right-0 top-0 w-[7%] sm:w-8' />
        </div>
        <p className="text-[#FFFFFF] xxl:text-[20px] sm:text-[18px] text-[14px] w-[100%] md:w-[75%] my-2">
        We have successfully processed over 340+ UK Tech Nation Visas for professionals and families, and the number continues to grow.
        </p>
      </div>

      {/* <div></div> */}
      <AnchorLink
            spy={true}
            smooth={true}
            to="ukHeroSection"
            className='rounded-md text-[#FFFFFF] bg-vestigreen text-center xxl:text-[20px] sm:text-[18px] text-[14px] px-2 xs:px-6 md:px-0 md:w-[36%] lg:w-[38%] xlg:w-[32%] 2xl:w-[25%] vlg:w-[16%] lg:pt-6 lg:pb-6 py-4 cursor-pointer'>
            Start Your Application</AnchorLink>
    </div>
  </div>
  )
}

export default UkNum
