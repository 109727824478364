import React from 'react'
import { useState, useEffect } from 'react'
import axios from "axios";
import NavBar from '../../containers/NavBar'
import Footer from '../../containers/Footer'
import UkTechHero from './UkTechHero'
import UkPotential from './UkPotential'
import UkNum from './UkNum'
import { VestiQues } from '../Partnership/VestiQues'
import Users from '../CanadianCareGiverVisa/Users'
import UkBenefits from './UkBenefits'
import { Notificationmodal } from '../helpers/modal/simplifiedmodal';
import { LiaTimesSolid } from "react-icons/lia";
import { UKTechNatEligibilityTest } from './UKTechNationEligTest';

const UkTechVisa = () => {
  const [open, setOpen] = useState(false);
  const [appointmentModal, setAppointmentModal] = useState(false);
  const [countries, setCountries] = useState(false);

  useEffect(() => fetchAllCountries(), []);

  const fetchAllCountries = () => {
    const url = "https://restcountries.com/v3.1/all";
    axios
      .get(url)
      .then((res) => {
        var options = (res.data ?? []).map((item) => ({
          image: item.flags.svg,
          label: item.name.common,
          value: item.cca3,
          currency: item.currencies,
        }));
        setCountries(options);
      })
      .catch((error) => error);
  };
  return (
    <div>
       <Notificationmodal open={open} onClick={() => setOpen(false)}>
        <LiaTimesSolid
          onClick={() => setOpen(false)}
          className="text-[#000] absolute top-4 right-8 text-[24px] cursor-pointer"
        />
        <div className="w-[100%] sm:w-[400px] md:w-[450px] flex flex-col items-center">
         <UKTechNatEligibilityTest
          countries={countries}
          close={() => setOpen(false)}
          />
        </div>
      </Notificationmodal>

      <NavBar/>
      <UkTechHero
       open={open}
       setOpen={setOpen}
       appointmentModal={appointmentModal}
       setAppointmentModal={setAppointmentModal}
       countries={countries}
       setCountries={setCountries}
       />
      <UkPotential/>
      <UkNum/>
      <UkBenefits/>
      <Users/>
      <VestiQues/>
      <Footer/>
    </div>
  )
}

export default UkTechVisa
