import React from "react";
import "./formatted.css";
import Typewriter from "./Typewriter";

const FormattedTextComponent = ({ text, typewritermsg, setTypewriterMsg }) => {
  const formattedText = text
    // Format headings (## Heading)
    .replace(/^##\s*(.*)$/gm, (match, content) => `<h2>${content}</h2>`)
    // Format bold text (**bold**)
    .replace(
      /\*\*(.*?)\*\*/g,
      (match, content) => `<strong>${content}</strong>`
    )
    // Format list items (- Item)
    .replace(/^\s*-\s*(.*)$/gm, (match, content) => `<li>${content}</li>`)
    // Wrap list items in <ul> or <ol>
    .replace(/(<li>.*<\/li>)/g, "<ul>$1</ul>")
    // Wrap each paragraph
    .replace(
      /^\s*(?!<h2>|<ul>|<\/ul>|<li>|<\/li>)(.*)$/gm,
      (match, content) => `<p>${content}</p>`
    );

  console.log(formattedText);
  return (
    <Typewriter
      text={formattedText}
      speed={5}
      typewritermsg={typewritermsg}
      setTypewriterMsg={setTypewriterMsg}
    />
    // <div
    //   className="FormattedTextComponent bg-[#fff] px-2 pt-2 text-[14px] rounded-b-[8px] rounded-tr-[8px]"
    //   dangerouslySetInnerHTML={{ __html: formattedText }}
    // />
  );
};

export default FormattedTextComponent;
