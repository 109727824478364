import greenTick from "../../assets/CanadianCareGiver/greenTick.png";

export const BenefitDataF = [
  {
    img: greenTick,
    title: "Career Growth",
    details:
      "Gain exposure to cutting-edge technologies  and collaborate with some of the world’s most innovative companies and academic institutions.",
  },
  {
    img: greenTick,
    title: "Competitive Salary",
    details:
      "Gain access to lucrative opportunities and high-paying roles in a competitive job market.",
  },
  {
    img: greenTick,
    title: "Work Flexibility",
    details:
      "Work without being tied to a specific employer.",
  },
];

export const BenefitDataS = [
  {
    img: greenTick,
    title: "Permanent Residency",
    details:
      "Work toward permanent residency for yourself and your family in the UK.",
  },
  {
    img: greenTick,
    title: "No Sponsorship Hassles",
    details:
      "You have full control over your career and choices because this visa doesn’t require employer sponsorship.",
  },
  {
    img: greenTick,
    title: "Support For Your Family",
    details:
      "Dependants can work or study in the UK, providing a strong foundation for your family’s future.",
  },
];