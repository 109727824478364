import React from "react";
import { Link } from "react-router-dom";
import errorsvg from "../assets/logo-icons/oops.svg";

export default function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-[#2B5219]">
      <div className="md:max-w-[40%] max-w-[90%] p-6  text-center ">
        <img src={errorsvg} alt className="w-[80%] xxl:w-[60%] mx-auto" />

        <p className="mt-6 text-[18px] font-bold">Oops! Page Not Found</p>
        <p className="text-[14px] mb-4 mt-2">
          The page you’re looking for isn’t available or might have been moved
          or renamed. Please check the URL or go back to the homepage to
          continue exploring.
        </p>

        <a
          href="https://wevesti.com"
          className="inline-block px-6 py-2 text-[14px] text-white bg-vestigreen  rounded-md transition-colors"
        >
          Back To Homepage
        </a>
      </div>
    </div>
  );
}
