import React from 'react'
import { Button } from '@material-tailwind/react'
import { openLink } from '../../helpers/helpers'
import techcity from '../../assets/CanadianCareGiver/TechCity Logo.svg'
import invesO from "../../assets/CanadianCareGiver/inves.svg";
import investT from "../../assets/CanadianCareGiver/inves2.svg";
import ukflag from '../../assets/UKTechNatVisa/United Kingdom (GB).png'
import ukHeroPic from '../../assets/UKTechNatVisa/Mask group.svg'

const UkTechHero = (props) => {
  return (
    <div id='ukHeroSection' className="bg-[#060E42] sm:mt-[4%] mt-[13%] xl:px-[6%] px-[4%] py-[8%] mb-[6%]">
      <div className="md:flex md:items-center md:justify-between">
        <div className="xxl:w-[50%] xlg:w-[45%] md:w-[50%]">
          <h1 className="vlg:text-[65px] xxl:text-[55px] md:text-[40px] text-[35px] font-bold text-[#ffffff]">
            <span className="text-[#67A948]">UK Tech Nation:</span>{" "}
            Your Path to a Brighter Future Starts Here
          </h1>
          <p className="vlg:text-[24px] xxl:text-[20px] text-18px text-[#ffffff] md:mt-1 mt-2">
          Looking to take your career to the next level? The UK Tech Nation Visa gives you access to world-class networking, career opportunities and resources to bring your expertise to life.
          </p>

          <div className="flex flex-row justify-between gap-4 md:mt-[8%] mt-[4%]">
            <Button
              onClick={() => props.setOpen(true)}
              className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] border border-vestigreen bg-transparent transition duration-700 ease-in-out"
            >
              See if you qualify
            </Button>
            <Button
              onClick={() =>
                openLink("https://wevesti.com/clarity-session/Tech%20Nation", "_blank")
              }
              className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
            >
              Speak To An Expert
            </Button>
          </div>

          <div className="md:mt-[7%] mt-[5%] md:mb-0 mb-[6%] flex items-center xlg:gap-2 gap-1">
            <p className="text-[#7D7D7D] vlg:text-[24px] xxl:text-[20px] sm:text-[14px] text-[12px] lg:w-[0rem] md:w-[40%] sm:w-[18%] xs:w-[26%] w-[44%]">
              As featured in:
            </p>
            <div className="flex items-center xxl:gap-[20%] xlg:gap-[30px] sm:gap-8 gap-4">
              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={invesO}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022",
                    "_blank"
                  )
                }
              />

              <img
                className="bg:w-[50%] xxl:w-[100%] w-[20%] cursor-pointer"
                src={techcity}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/",

                    "_blank"
                  )
                }
              />

              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={investT}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="relative flex items-center justify-center">
          <img
            className="vlg:w-[40rem] xxl:w-[33rem] xlg:w-[30rem] lg:w-[25rem] md:w-[20rem] w-[70%] "
            src={ukHeroPic}
            alt=""
          />
          <img
            className="absolute xxl:bottom-[100px] sm:bottom-[50px] bottom-[40px] xxl:right-3 md:right-[-15px] sm:right-[90px] right-[30px] md:w-[30%] w-[22%]"
            src={ukflag}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default UkTechHero
