import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaArrowRightArrowLeft } from "react-icons/fa6";

const UserLocation = (props) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        // Call IP-API to get location data
        const response = await axios.get("https://ip-api.com/json/");
        // console.log(response);
        if (response.data && response.data.country) {
          props.setUserCountry(
            `${response.data.city}, ${response.data.country}`
          );
        } else {
          console.log("Unable to fetch location");
        }
      } catch (err) {
        console.log("Error fetching location: " + err.message);
      }
    };

    fetchLocation();
  }, []);

  const handleLocationChange = (event) => {
    props.setNewLocation(event.target.value);
  };

  //    height={props.height}
  //         setHeight={props.setHeight}
  //         smallheight={props.smallheight}
  //         setSmallHeight={props.setSmallHeight}

  return (
    <div className="w-full bg:w-[80%] mt-2">
      {/* <p className="text-vestigreen mb-2">Where do you want to move to? </p> */}
      <div className="flex gap-2 bg:gap-3 items-center w-full">
        <div className="w-full">
          {/* Pre-filled input field with userCountry */}
          <label className="text-[13px] mb-1 text-[#ACACAC]">Origin:</label>
          <input
            type="text"
            placeholder="Lagos, Nigeria"
            value={props.userCountry}
            onChange={(e) => props.setUserCountry(e.target.value)} // Optional: allow editing userCountry
            disabled={false} // Disable editing if not required
            className="text-[#060E42] text-[13px] p-2 rounded-md outline-none"
          />
        </div>
        <FaArrowRightArrowLeft className="text-[28px] mt-4 " />

        <div className="w-full">
          {/* Second input field for the user to enter a new location */}
          <label className="text-[13px] mb-1 text-[#ACACAC]">
            Desired Destination:
          </label>
          <input
            type="text"
            placeholder="Texas, US"
            value={props.newLocation}
            onChange={handleLocationChange}
            className="text-[#060E42] text-[13px] p-2 rounded-md outline-none"
          />
        </div>
      </div>

      {error && !props.newLocation ? (
        <p className="text-red-700 w-full mb-1  text-[12px] text-center font-semibold">
          *Enter Desired Destination to proceed
        </p>
      ) : (
        <></>
      )}

      {error && !props.userCountry ? (
        <p className="text-red-700 w-full mb-1  text-[12px] text-center font-semibold">
          *Enter Origin to proceed
        </p>
      ) : (
        <></>
      )}
      {props.newLocation && props.userCountry ? (
        <div
          onClick={() => {
            props.Handlepost();
          }}
          className="font-[600] cursor-pointer text-sm capitalize p-4 rounded-md text-center mb-12 w-[100%] lg:w-[100%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
        >
          Discover Your Pathway with Miai ™
        </div>
      ) : (
        <div
          onClick={() => {
            setError(true);
          }}
          className="font-[600] cursor-pointer text-sm capitalize p-4 rounded-md text-center mb-12 w-[100%] lg:w-[100%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
        >
          Discover Your Pathway with Miai ™
        </div>
      )}

      {/* </Link> */}
    </div>
  );
};

export default UserLocation;
